<template>
    <section id="article" class="About wrapper">
      <h1>
        {{ $t("home.about.title") }}
      </h1>
      <article>
        <p v-html="$t('home.about.description')"></p>
      </article>
    </section>
</template>

<script>
export default {
  name: "About",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang="scss">
/****  About  ****/
.About {
  width: 100%;
  height: auto;
}
</style>
